import './TopLinks.css';
export default function TopLinks() {
  return (
    <div className="TopLinks">
      <a href="https://dexscreener.com/" className="TopLinkContainer" target="_blank" rel="noopener noreferrer">
        CHART
      </a>
      <a href="https://x.com/" className="TopLinkContainer" target="_blank" rel="noopener noreferrer">
        X
      </a>
      <a href="https://t.me/" className="TopLinkContainer" target="_blank" rel="noopener noreferrer">
        TELEGRAM
      </a>
    </div>
  );
}