import React, { useEffect, useRef } from 'react';
import './Home.css';
import TopLinks from '../Component/TopLinks';
import Cone from '../assets/cone.png';

function Home() {

  return (
    <div className="Home">
      <TopLinks />
      <div className="HomeContentContainer">
        <div className="HomeContentImageContainer">
          <img className="HomeContentImage" src={Cone} alt="Cone" />
        </div>
        <div className="HomeContentDescription">
          <div className="HomeContentText">
            <div className="HomeContentTextTitle">Cone</div>
            <div className="HomeContentTextText">Just a pointy zebra. Securing the blockchain. Always happy.</div>
          </div>
          <div className="HomeContentBuyButton">
            <a  className="HomeContentBuyButtonLink" href="https://app.uniswap.org/swap?outputCurrency=" target="_blank" rel="noopener noreferrer">
              BUY NOW
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
